import React from "react";
import { CartProvider } from "./src/hooks/cartContext";
import { CustomerProvider } from "./src/hooks/customerContext";
import { CustomerTokenProvider } from "./src/hooks/customerTokenContext";
import "./src/assets/style/layout.css";
import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: "https://b53b62ee9af52cc1ad6ce9875ca53963@o4508647206486016.ingest.de.sentry.io/4508647207927888",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [window.location.origin, /commercelayer/i],
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0,
  tracePropagationTargets: [window.location.origin, /commercelayer/i],
  // Session Replay
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

export const wrapRootElement = ({ element }) => (
  <CustomerTokenProvider>
    <CustomerProvider>
      <CartProvider>{element}</CartProvider>
    </CustomerProvider>
  </CustomerTokenProvider>
);
