import React, { memo, useEffect, useState } from "react";
import { Box, Grid, Text, Flex, Heading } from "theme-ui";
import { useClSdk } from "../hooks/useClSdk";
import { getProductPath } from "../utils/path";
import { InboundLink } from "./link";
import { GatsbyImage } from "gatsby-plugin-image";
import RemoveFromCart from "../components/removeFromCart";
import LineItemQuantity from "../components/lineItemQuantity";
import getSkuData from "../hooks/getSkuData";
import PlaceholderImage from "../assets/img/placeholder-image.png";
import ThumbPrice from "../components/thumbPrice";
import ThumbProductDetails from "../components/thumbProductDetails";
import { Image } from "react-datocms";

const CartProduct = memo(({ sku, handleSkuLoaded, updateQuantity }) => {
  const [clSkuDetails, setClSkuDetails] = useState(null);
  const [datoSkusData, setDatoSkusData] = useState();
  const cl = useClSdk();

  useEffect(() => {
    const handleLoadSkusDatoData = async () => {
      const skuCode = sku.code ? sku.code : sku.sku_code;
      const datoSkusData = await getSkuData(skuCode);
      setClSkuDetails({ ...datoSkusData[0], ...sku });
    };

    if (!clSkuDetails) handleLoadSkusDatoData();
  }, [sku]);

  
  return (
    <Box>
      {clSkuDetails && (
        <>
          <Grid
            sx={{
              gridTemplateColumns: ["83px 1fr", "83px 1fr", "168px 1fr"],
            }}
            gap={[3, 10]}
          >
            <Flex sx={{ justifyItems: "baseline", width: "100%" }}>
              <Box
                sx={{
                  border: "1px solid",
                  height: ["81px", "81px", "168px"],
                  borderColor: "dark",
                  width: "100%",
                }}
              >
                {clSkuDetails.images && clSkuDetails.images.length > 0 ? (
                  <>
                  <GatsbyImage
                    image={clSkuDetails.images[0].gatsbyImageData}
                    alt=""
                  />
                  {clSkuDetails.images[0].responsiveImage && (
                    <Image
                      data={clSkuDetails.images[0].responsiveImage}
                    />
                  )}
                  </>
                ) : (
                  <Box
                    sx={{
                      height: "100%",
                      img: {
                        width:"100%",
                        height: "100%",
                        objectFit: "contain",
                      },
                      backgroundColor: "light",
                    }}
                  >
                    <img src={PlaceholderImage} />
                  </Box>
                )}
              </Box>
            </Flex>
            <Flex
              sx={{
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Flex
                sx={{
                  pb: [2],
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <InboundLink
                    to={getProductPath(sku)}
                    sx={{
                      textDecoration: "none",
                      color: "dark",
                    }}
                  >
                    <Heading
                      as={"h3"}
                      variant="h2"
                      sx={{
                        color: "dark",
                        fontWeight: "400",
                        my: [0],
                        fontSize: [1, 5, 5],
                      }}
                    >
                      {clSkuDetails.name}
                    </Heading>
                  </InboundLink>
                </Box>
                <Box sx={{ width: "20%", textAlign: "right" }}>
                  <RemoveFromCart sku={sku} />
                </Box>
              </Flex>
              <Box sx={{ pb: [2], color: "lightBorder", fontSize:[1,2] }}>
                {clSkuDetails.code ? clSkuDetails.code : clSkuDetails.sku_code}
              </Box>
              <ThumbProductDetails item={clSkuDetails} />
              <ThumbPrice item={clSkuDetails} />
              <Box sx={{ display: ["none", "block", "block"] }}>
                <LineItemQuantity
                  lineItem={sku}
                  quantity={sku.quantity}
                  updateQuantity={updateQuantity}
                />
              </Box>
            </Flex>
          </Grid>

          <Box sx={{ display: ["block", "none"] }}>
            <LineItemQuantity
              lineItem={sku}
              quantity={sku.quantity}
              updateQuantity={updateQuantity}
            />
          </Box>
        </>
      )}
    </Box>
  );
});

export default CartProduct;
